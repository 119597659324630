const firebaseConfig = {
    apiKey: "AIzaSyALHS4ow0DI4nWAYD7D2ne_DEPSkIlzDuY",
    authDomain: "lendmycar-d7528.firebaseapp.com",
    projectId: "lendmycar-d7528",
    storageBucket: "lendmycar-d7528.appspot.com",
    messagingSenderId: "401998479678",
    appId: "1:401998479678:web:460631f6e84a7e9f98ec6f",
    measurementId: "G-TBXNSHM83S"
};

export default firebaseConfig;